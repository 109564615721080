import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Typography,
} from "@mui/material";
import * as React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BookIcon from "@mui/icons-material/Book";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import KeyIcon from "@mui/icons-material/Key";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import {
  LicenseBodyParsed,
  LicenseFeatures,
  LicenseKeyListingDisplay,
  LicenseListing,
  UserData,
  UserRole,
} from "../model";
import Network from "../network";
import { MainTheme } from "../theme";
import CreateEditLicenseDialog from "./CreateEditLicenseDialog";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { LicenseDisplayAdapter } from "../licenseDisplayAdapter";

export interface LicenseItemViewProps {
  userData: UserData;
  licenseKey: LicenseKeyListingDisplay;
  goBack: any;
}

function LicenseItemView({
  userData,
  licenseKey,
  goBack,
  ...props
}: LicenseItemViewProps) {
  const hasBeenCalled = React.useRef(false);
  const [licenses, setLicenses] = React.useState<LicenseListing[]>([]);
  const [revocationCodes, setRevocationCodes] = React.useState<
    Map<string, string>
  >(new Map<string, string>());

  const [adminCodeDialogOpen, setAdminCodeDialogOpen] = React.useState(false);

  const [adminPasswordRevocationCode, setAdminPasswordRevocationCode] =
    React.useState<string>("");

  const handleAdminCodeDialogOpen = async () => {
    const resp = await Network.Get(
      "registrations/admin_code/" + licenseKey.key
    );

    if (resp.status === 200) {
      setAdminPasswordRevocationCode(resp.data as string);
    } else {
      setAdminPasswordRevocationCode("code not available");
    }
    setAdminCodeDialogOpen(true);
  };

  const handleAdminCodeDialogClose = () => {
    setAdminPasswordRevocationCode("");
    setAdminCodeDialogOpen(false);
  };

  const [revokeLicenseKeyDialogOpen, setRevokeLicenseKeyDialogOpen] =
    React.useState(false);

  const [revokeElement, setRevokeElement] =
    React.useState<LicenseListing | null>(null);

  const [createEditLicenseDialogOpen, setCreateEditLicenseDialogOpen] =
    React.useState(false);

  const [editLicenseItem, setEditLicenseItem] =
    React.useState<LicenseListing | null>(null);

  const [licensesFeatures, setLicensesFeatures] = React.useState<
    LicenseFeatures[]
  >([]);

  const loadLicensesFeatures = async () => {
    const resp = await Network.Get("licenses");
    if (resp.status === 200) {
      const data = resp.data as LicenseFeatures[];
      setLicensesFeatures(data);
    }
  };

  const openRevokeDialog = (element: LicenseListing) => {
    setRevokeElement(element);
    setRevokeLicenseKeyDialogOpen(true);
  };

  const closeRevokeDialog = () => {
    setRevokeElement(null);
    setRevokeLicenseKeyDialogOpen(false);
  };

  const revokeLicense = async () => {
    // Expected to be not null
    if (revokeElement) {
      const resp = await Network.Post(
        "licenses/revoke/" + revokeElement.uuid,
        {}
      );
      if (resp.status === 200) {
        closeRevokeDialog();
        loadData(licenseKey.key);
      }
    }
  };

  const closeCreateLicenseDialog = (updated: boolean) => {
    if (updated) loadData(licenseKey.key);
    setEditLicenseItem(null);
    setCreateEditLicenseDialogOpen(false);
  };

  const loadData = async (key: string) => {
    const resp = await Network.Get("licensekeys/key/" + key);
    if (resp.status === 200) {
      const licenses = resp.data as LicenseListing[];
      licenses.forEach(
        (license) =>
          (license.license = JSON.parse(
            license.license as string
          ) as LicenseBodyParsed)
      );
      setLicenses(licenses);

      let codes: Map<string, string> = new Map<string, string>();
      for (let i = 0; i < licenses.length; ++i) {
        if (licenses[i].revoked) {
          const resp = await Network.Get(
            `licenses/revocation_code/${licenseKey.key}/${licenses[i].uuid}`
          );
          if (resp.status === 200) {
            codes.set(licenses[i].uuid, resp.data as string);
          }
        }
      }
      setRevocationCodes(codes);
    }
  };
  const constructor = async () => {
    if (hasBeenCalled.current) return;
    hasBeenCalled.current = true;

    loadLicensesFeatures();

    loadData(licenseKey.key);
  };

  React.useEffect(() => {
    constructor();
  });

  const downloadLicense = async (element: LicenseListing) => {
    const resp = await Network.Download("licenses/by_uuid/" + element.uuid);
    if (resp.status === 200) {
      const href = URL.createObjectURL(resp.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", element.license_type + ".lic");
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    }
  };

  const editLicense = (element: LicenseListing) => {
    setEditLicenseItem(element);
    setCreateEditLicenseDialogOpen(true);
  };
  return (
    <Box>
      {createEditLicenseDialogOpen && (
        <CreateEditLicenseDialog
          open={createEditLicenseDialogOpen}
          closeDialog={closeCreateLicenseDialog}
          licenseKey={licenseKey.key}
          licenseKeyDistributorName={licenseKey.distributor}
          item={editLicenseItem}
        />
      )}
      <Dialog open={revokeLicenseKeyDialogOpen} onClose={closeRevokeDialog}>
        <DialogTitle
          sx={{
            backgroundColor: MainTheme.palette.error.main,
            color: MainTheme.palette.common.white,
          }}
        >
          Revoke License
        </DialogTitle>
        <DialogContent sx={{ minWidth: 600 }}>
          <Typography sx={{ pt: 2 }}>
            This license is for software <b>{licenseKey.key}</b> and is
            activated for license key <b>{revokeElement?.uuid}</b>
          </Typography>
          <Typography sx={{ pt: 1 }}>
            Are you <b>SURE</b> you want to revoke this license?
          </Typography>
          <Typography sx={{ pt: 1 }}>
            <b>THIS ACTION CANNOT BE UNDONE!</b>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeRevokeDialog} color="secondary" fullWidth>
            Cancel
          </Button>
          <Button
            onClick={revokeLicense}
            variant="contained"
            color="error"
            fullWidth
          >
            Revoke
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={adminCodeDialogOpen} onClose={handleAdminCodeDialogClose}>
        <DialogTitle>Admin Password Restore Code</DialogTitle>
        <DialogContent>
          <Typography sx={{ pt: 2 }}>
            Please share this code with a client:{" "}
          </Typography>
          <Typography>
            <b>{adminPasswordRevocationCode}</b>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleAdminCodeDialogClose}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Box sx={{ flexDirection: "row", display: "flex" }}>
        <IconButton color="primary" onClick={goBack}>
          <ArrowBackIcon />
        </IconButton>
        <Box sx={{ flex: 1, textAlign: "center" }}>
          <Typography variant="h4" color="primary">
            {`License Key: ${licenseKey.key}`}
          </Typography>
          {licenseKey.rejoyce_serial_numbers !== "" && (
            <Typography variant="h5" color="primary">
              {`ReJoyce Serial Numbers: ${licenseKey.rejoyce_serial_numbers}`}
            </Typography>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          mt: 5,
          display: "flex",
          justifyContent: "center",
        }}
      >
        {userData.user_role !== UserRole.Distributor && (
          <Button
            variant="contained"
            color="secondary"
            startIcon={<BookIcon />}
            onClick={() => setCreateEditLicenseDialogOpen(true)}
          >
            New License
          </Button>
        )}
      </Box>
      <List>
        {licenses.map((element) => (
          <ListItem
            key={element.uuid}
            sx={{ flexDirection: "column", justifyContent: "center" }}
          >
            <Paper sx={{ p: 3, m: 2 }}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>{`${LicenseDisplayAdapter.GetDisplayName(
                    element.license_type
                  )}: ${element.uuid}`}</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ width: "60vw" }}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            Enabled
                          </TableCell>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            Feature Name
                          </TableCell>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            Expires
                          </TableCell>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            Options
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {licensesFeatures
                          .filter((elem) => elem.key === element.license_type)
                          .map((license) =>
                            license.features.map((featureName) => (
                              <TableRow key={featureName}>
                                <TableCell>
                                  {(
                                    element.license as LicenseBodyParsed
                                  ).features.find(
                                    (feature) => feature.feature === featureName
                                  ) !== undefined ? (
                                    <CheckIcon />
                                  ) : (
                                    <CloseIcon sx={{ color: "red" }} />
                                  )}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {featureName}
                                </TableCell>
                                <TableCell>
                                  {(
                                    element.license as LicenseBodyParsed
                                  ).features
                                    .filter(
                                      (feature) =>
                                        feature.feature === featureName
                                    )
                                    .map((feature) =>
                                      feature.expires ? feature.expires : "-"
                                    )}
                                </TableCell>
                                <TableCell>
                                  {(
                                    element.license as LicenseBodyParsed
                                  ).features
                                    .filter(
                                      (feature) =>
                                        feature.feature === featureName
                                    )
                                    .map((feature) =>
                                      feature.options ? feature.options : "-"
                                    )}
                                </TableCell>
                              </TableRow>
                            ))
                          )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
              <Typography sx={{ mt: 2, textAlign: "right" }}>
                {`Distributor: ${
                  (element.license as LicenseBodyParsed).distributor
                }`}
              </Typography>
              <Typography sx={{ mt: 2, textAlign: "right" }}>
                {`Created at: ${new Date(
                  element.created_at
                ).toLocaleDateString()} (${new Date(
                  element.created_at
                ).toLocaleTimeString()})` +
                  (element.revoked ? `, REVOKED!` : ``)}
              </Typography>
              {!element.revoked && element.expires !== null && (
                <Typography sx={{ mt: 2, textAlign: "right" }}>
                  {`Expires: ${new Date(
                    element.expires + "T00:00:00"
                  ).toLocaleDateString()}`}
                </Typography>
              )}
              {element.revoked ? (
                <Box
                  sx={{
                    mt: 2,
                    justifyContent: "flex-end",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Typography>Revocation code:&nbsp;</Typography>
                  <Typography
                    sx={{
                      pl: 1,
                      pr: 1,
                      fontSize: 14,
                      color: MainTheme.palette.error.main,
                      backgroundColor: MainTheme.palette.grey[200],
                    }}
                  >
                    {revocationCodes.has(element.uuid)
                      ? revocationCodes.get(element.uuid)
                      : "Loading..."}
                  </Typography>
                </Box>
              ) : (
                <Box
                  sx={{
                    mt: 2,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    sx={{ pr: 3 }}
                    startIcon={<DeleteIcon />}
                    color="error"
                    onClick={() => openRevokeDialog(element)}
                  >
                    Revoke
                  </Button>
                  <Button
                    sx={{ pr: 3 }}
                    startIcon={<CloudDownloadIcon />}
                    color="secondary"
                    onClick={() => downloadLicense(element)}
                  >
                    Download
                  </Button>
                  <Button
                    startIcon={<EditIcon />}
                    color="primary"
                    onClick={() => editLicense(element)}
                  >
                    Edit
                  </Button>
                </Box>
              )}
            </Paper>
          </ListItem>
        ))}
      </List>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        {userData.user_role === UserRole.Admin && (
          <Button
            color="primary"
            variant="contained"
            startIcon={<KeyIcon />}
            onClick={handleAdminCodeDialogOpen}
          >
            Admin Password Restore Code
          </Button>
        )}
      </Box>
    </Box>
  );
}

export default LicenseItemView;
