import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import * as React from "react";
import Network from "../network";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import SelectedLogFileView, {
  ParseLogFileName,
} from "../components/SelectedLogFileView";

interface LogFileEntry {
  id: number;
  fileName: string;
  displayName: string;
}

function ActionLogView() {
  const hasBeenCalled = React.useRef(false);

  const [logFiles, setLogFiles] = React.useState<LogFileEntry[]>([]);

  const [selectedLogFile, setSelectedLogFile] = React.useState("");
  const [selectedLogFileContent, setSelectedLogFileContent] =
    React.useState("");

  const constructor = async () => {
    if (hasBeenCalled.current) return;
    hasBeenCalled.current = true;

    const resp = await Network.Get("settings/logfiles");
    if (resp.status === 200) {
      const files: string[] = resp.data;
      let entries: LogFileEntry[] = [];
      for (let i = 0; i < files.length; ++i) {
        entries.push({
          id: i,
          fileName: files[i],
          displayName: ParseLogFileName(files[i]),
        });
      }
      setLogFiles(entries);
    }
  };

  React.useEffect(() => {
    constructor();
  });

  const handleLogFileSelected = async (name: string) => {
    const resp = await Network.Get("settings/logfiles/" + name);
    if (resp.status === 200) {
      setSelectedLogFile(name);
      setSelectedLogFileContent(resp.data);
    }
  };

  const handleGoBack = () => {
    setSelectedLogFile("");
    setSelectedLogFileContent("");
  };

  return (
    <Box>
      {selectedLogFile.length === 0 ? (
        <List disablePadding>
          {logFiles.map((element) => (
            <Box key={element.id}>
              <ListItem>
                <ListItemButton
                  onClick={() => handleLogFileSelected(element.fileName)}
                >
                  <ListItemIcon>
                    <InsertDriveFileIcon />
                  </ListItemIcon>
                  <ListItemText>{element.displayName}</ListItemText>
                </ListItemButton>
              </ListItem>
              <Divider />
            </Box>
          ))}
        </List>
      ) : (
        <SelectedLogFileView
          goBack={handleGoBack}
          logFileContent={selectedLogFileContent}
          logFileName={selectedLogFile}
        />
      )}
    </Box>
  );
}

export default ActionLogView;
