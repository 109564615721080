import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import {
  DataGrid,
  GridColumns,
  GridRowModel,
  GridSelectionModel,
} from "@mui/x-data-grid";
import * as React from "react";
import {
  LicenseBodyParsed,
  LicenseListing,
  RejoyceSerialNumberDisplayEntry,
} from "../model";
import Network from "../network";
import { MainTheme } from "../theme";

const columns: GridColumns = [
  { field: "key", headerName: "License Key", width: 240 },
  { field: "uuid", headerName: "License UUID", width: 293 },
];

interface LicenseKeyUUIDDisplayItem {
  key: string;
  uuid: string;
}

interface RevokeMultipleLicensesDialogProps {
  open: boolean;
  closeDialog: any;
  item: RejoyceSerialNumberDisplayEntry;
}

function RevokeMultipleLicensesDialog({
  open,
  closeDialog,
  item,
}: RevokeMultipleLicensesDialogProps) {
  const hasBeenCalled = React.useRef(false);
  const [isLoading, setIsLoading] = React.useState(true);

  const [revokeLicenseSelectionModel, setRevokeLicenseSelectionModel] =
    React.useState<GridSelectionModel>([]);

  const [rows, setRows] = React.useState<LicenseKeyUUIDDisplayItem[]>([]);

  const constructor = async () => {
    if (hasBeenCalled.current) return;
    hasBeenCalled.current = true;

    const resp = await Network.Get(
      "rejoyce_serial_numbers/licenses_rejoyce/" + item.id
    );

    if (resp.status === 200) {
      const data = resp.data as LicenseListing[];
      data.forEach(
        (elem) => (elem.license = JSON.parse(elem.license as string))
      );

      let rows: LicenseKeyUUIDDisplayItem[] = [];

      for (let i = 0; i < data.length; ++i)
        rows.push({
          key: (data[i].license as LicenseBodyParsed).key,
          uuid: data[i].uuid,
        });

      setRows(rows);
    }
    setIsLoading(false);
  };

  React.useEffect(() => {
    constructor();
  });

  const processRowUpdate = async (newRow: GridRowModel) => {
    const updatedRow = newRow as LicenseKeyUUIDDisplayItem;
    setRows(
      rows.map((row) => (row.uuid === updatedRow.uuid ? updatedRow : row))
    );
    return newRow;
  };

  const handleSubmit = async () => {
    const resp = await Network.Post(
      "rejoyce_serial_numbers/revoke_licenses",
      revokeLicenseSelectionModel
    );

    if (resp.status === 200) closeDialog(true);
  };

  return (
    <Dialog open={open} fullWidth>
      {!isLoading && (
        <Box>
          <DialogTitle
            sx={{
              backgroundColor: MainTheme.palette.error.main,
              color: MainTheme.palette.common.white,
            }}
          >
            {`Revoke Licenses for ReJoyce Serial Number ${item.serial_number}`}
          </DialogTitle>
          <DialogContent dividers>
            <DataGrid
              sx={{ height: "400px", width: "584px", margin: "-16px" }}
              getRowId={(row) => row.uuid}
              rows={rows}
              columns={columns}
              processRowUpdate={processRowUpdate}
              checkboxSelection
              onSelectionModelChange={(newSelectionModel) => {
                setRevokeLicenseSelectionModel(newSelectionModel);
              }}
              selectionModel={revokeLicenseSelectionModel}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => closeDialog(false)} color="secondary">
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              variant="contained"
              color="error"
              disabled={revokeLicenseSelectionModel.length === 0}
            >
              Revoke
            </Button>
          </DialogActions>
        </Box>
      )}
    </Dialog>
  );
}

export default RevokeMultipleLicensesDialog;
