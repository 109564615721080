import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ValidatedTextField from "./ValidatedTextField";
import * as StringValidators from "../stringValidators";
import Network from "../network";
import {
  DistributorDefaultsListing,
  DistributorLicense,
  DistributorLicenseFeature,
  DistributorListing,
  LicenseFeatures,
  LicenseFeaturesDisplay,
  ReJoyceSerialNumber,
  RejoyceSerialNumberDisplayEntry,
} from "../model";
import {
  Box,
  DialogActions,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import {
  DataGrid,
  GridColumns,
  GridPreProcessEditCellProps,
  GridRowModel,
  GridSelectionModel,
} from "@mui/x-data-grid";
import { Moment } from "moment";
import moment from "moment";

const columns: GridColumns = [
  { field: "name", headerName: "Feature", width: 180 },
  {
    field: "expires",
    headerName: "Expires (double-click to set)",
    type: "date",
    width: 200,
    editable: true,
    preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
      return params.props.value;
    },
  },
  { field: "options", headerName: "Options", editable: true },
];

interface FeatureList {
  selections: number[];
  features: LicenseFeaturesDisplay[];
}

const prepareFeaturesList = (
  defaults: DistributorDefaultsListing,
  licenseFeatures: LicenseFeatures
) => {
  return new Promise((resolve, reject) => {
    let features: LicenseFeaturesDisplay[] = [];
    let selections: number[] = [];
    licenseFeatures.features.forEach((feature) => {
      const defaultsFeature: DistributorLicenseFeature | undefined =
        defaults.license_defaults.features.find(
          (elem) => elem.name === feature
        );

      if (defaultsFeature !== undefined) selections.push(features.length);

      features.push({
        id: features.length,
        name: feature,
        expires: null,
        options:
          defaultsFeature !== undefined
            ? defaultsFeature.options
              ? defaultsFeature.options
              : ""
            : "",
      });
    });

    let result: FeatureList = { selections, features };

    resolve(result);
  });
};

export interface AddRejoyceSerialNumberDialogProps {
  open: boolean;
  handleClose: any;
  editItem: RejoyceSerialNumberDisplayEntry | null;
}

function AddEditRejoyceSerialNumberDialog({ open, handleClose, editItem }) {
  const hasBeenCalled = React.useRef(false);

  const [isEdit] = React.useState(editItem !== null);

  const [serialNumber, setSerialNumber] = React.useState({
    value: "",
    error: "",
  });

  const [copies, setCopies] = React.useState({
    value: "1",
    error: "",
  });

  const [rehabExpiresDate, setRehabExpiresDate] = React.useState<Moment | null>(
    null
  );

  const [rehabOptions, setRehabOptions] = React.useState("");

  const [rehabLicenseTypeFeatures, setRehabLicenseTypeFeatures] =
    React.useState<LicenseFeatures>();

  const [rehabLicenseFeatures, setRehabLicenseFeatures] = React.useState<
    LicenseFeaturesDisplay[]
  >([]);

  const [rehabDistributorDefaults, setRehabDistributorDefaults] =
    React.useState<DistributorDefaultsListing[]>([]);

  const [rehabSelectionModel, setRehabSelectionModel] =
    React.useState<GridSelectionModel>([]);

  const [rehabDistributorDefaultsId, setRehabDistributorDefaultsId] =
    React.useState<string>("");

  const [rejoyceExpiresDate, setRejoyceExpiresDate] =
    React.useState<Moment | null>(null);

  const [rejoyceOptions, setRejoyceOptions] = React.useState("");

  const [rejoyceLicenseTypeFeatures, setRejoyceLicenseTypeFeatures] =
    React.useState<LicenseFeatures>();

  const [rejoyceLicenseFeatures, setRejoyceLicenseFeatures] = React.useState<
    LicenseFeaturesDisplay[]
  >([]);

  const [rejoyceDistributorDefaults, setRejoyceDistributorDefaults] =
    React.useState<DistributorDefaultsListing[]>([]);

  const [rejoyceSelectionModel, setRejoyceSelectionModel] =
    React.useState<GridSelectionModel>([]);

  const [rejoyceDistributorDefaultsId, setRejoyceDistributorDefaultsId] =
    React.useState<string>("");

  const [distributorId, setDistributorId] = React.useState<string>("");

  const [distributors, setDistributors] = React.useState<DistributorListing[]>(
    []
  );

  const handleDistributorChange = async (event: SelectChangeEvent) => {
    setDistributorId(event.target.value);

    const resp = await Network.Get(
      "distributor_defaults/" + event.target.value
    );
    if (resp.status === 200) {
      let data = resp.data as DistributorDefaultsListing[];
      let rehab_defaults = data.filter(
        (element) => element.license_defaults.key === "com.rehabtronics.rehab"
      );

      setRehabDistributorDefaults(rehab_defaults);

      let rehab_default: DistributorDefaultsListing = rehab_defaults.filter(
        (row) => row.alias === "ReHab Default"
      )[0];
      setRehabDistributorDefaultsId(rehab_default.id.toString());

      prepareFeaturesList(rehab_default, rehabLicenseTypeFeatures!).then(
        (result) => {
          let { selections, features } = result as FeatureList;
          setRehabSelectionModel(selections);
          setRehabLicenseFeatures(features);
        }
      );

      let rejoyce_defaults = data.filter(
        (element) => element.license_defaults.key === "com.rehabtronics.rejoyce"
      );

      setRejoyceDistributorDefaults(rejoyce_defaults);

      let rejoyce_default: DistributorDefaultsListing = rejoyce_defaults.filter(
        (row) => row.alias === "ReJoyce Default"
      )[0];

      setRejoyceDistributorDefaultsId(rejoyce_default.id.toString());

      prepareFeaturesList(rejoyce_default, rejoyceLicenseTypeFeatures!).then(
        (result) => {
          let { selections, features } = result as FeatureList;
          setRejoyceSelectionModel(selections);
          setRejoyceLicenseFeatures(features);
        }
      );
    }
  };

  const setFeatures = (
    license_defaults: DistributorLicense,
    license_features: LicenseFeatures
  ) => {
    return new Promise((resolve, eject) => {
      let features: LicenseFeaturesDisplay[] = [];
      let selections: number[] = [];
      license_features.features.forEach((feature) => {
        const itemFeature: DistributorLicenseFeature | undefined =
          license_defaults.features.find((elem) => elem.name === feature);

        if (itemFeature !== undefined) selections.push(features.length);

        features.push({
          id: features.length,
          name: feature,
          expires:
            itemFeature !== undefined
              ? itemFeature.expires
                ? new Date(itemFeature.expires + "T00:00:00")
                : null
              : null,
          options:
            itemFeature !== undefined
              ? itemFeature.options
                ? itemFeature.options
                : ""
              : "",
        });
      });
      let result: FeatureList = { selections, features };
      resolve(result);
    });
  };

  const constructor = async () => {
    if (hasBeenCalled.current) return;
    hasBeenCalled.current = true;

    let resp = await Network.Get("distributors");
    if (resp.status === 200) {
      setDistributors(resp.data as DistributorListing[]);
    }

    let rehabLicenseTypeFeatures: LicenseFeatures;
    let rejoyceLicenseTypeFeatures: LicenseFeatures;
    resp = await Network.Get("licenses");
    if (resp.status === 200) {
      rehabLicenseTypeFeatures = (resp.data as LicenseFeatures[]).filter(
        (elem) => elem.key === "com.rehabtronics.rehab"
      )[0];
      setRehabLicenseTypeFeatures(rehabLicenseTypeFeatures);

      rejoyceLicenseTypeFeatures = (resp.data as LicenseFeatures[]).filter(
        (elem) => elem.key === "com.rehabtronics.rejoyce"
      )[0];
      setRejoyceLicenseTypeFeatures(rejoyceLicenseTypeFeatures);
    }

    if (isEdit) {
      setSerialNumber({ value: editItem!.serial_number, error: "" });
      setDistributorId(editItem!.distributor_id);

      const resp = await Network.Get(
        "distributor_defaults/" + editItem!.distributor_id
      );
      if (resp.status === 200) {
        let data = resp.data as DistributorDefaultsListing[];

        let rehab_distributor_defaults = data.filter(
          (element) => element.license_defaults.key === "com.rehabtronics.rehab"
        );

        setRehabDistributorDefaultsId("");
        setRehabDistributorDefaults(rehab_distributor_defaults);

        let rejoyce_distributor_defaults = data.filter(
          (element) =>
            element.license_defaults.key === "com.rehabtronics.rejoyce"
        );
        setRejoyceDistributorDefaultsId("");
        setRejoyceDistributorDefaults(rejoyce_distributor_defaults);
      }

      setCopies({ value: editItem!.total_copies.toString(), error: "" });

      let license_defaults: DistributorLicense[] = JSON.parse(
        editItem!.license_defaults
      );

      let rehab_license_defaults = license_defaults.filter(
        (elem) => elem.key === "com.rehabtronics.rehab"
      )[0];
      if (rehab_license_defaults.expires !== null)
        setRehabExpiresDate(moment(rehab_license_defaults.expires));
      setRehabOptions(
        rehab_license_defaults.options ? rehab_license_defaults.options! : ""
      );

      setFeatures(rehab_license_defaults, rehabLicenseTypeFeatures!).then(
        (result) => {
          let { selections, features } = result as FeatureList;
          setRehabSelectionModel(selections);
          setRehabLicenseFeatures(features);
        }
      );

      let rejoyce_license_defaults = license_defaults.filter(
        (elem) => elem.key === "com.rehabtronics.rejoyce"
      )[0];
      if (rejoyce_license_defaults.expires !== null)
        setRejoyceExpiresDate(moment(rejoyce_license_defaults.expires));
      setRejoyceOptions(
        rejoyce_license_defaults.options
          ? rejoyce_license_defaults.options!
          : ""
      );

      setFeatures(rejoyce_license_defaults, rejoyceLicenseTypeFeatures!).then(
        (result) => {
          let { selections, features } = result as FeatureList;
          setRejoyceSelectionModel(selections);
          setRejoyceLicenseFeatures(features);
        }
      );
    }
  };

  React.useEffect(() => {
    constructor();
  });

  const handleSubmit = async () => {
    const serialNumberError = StringValidators.rejoyceSerialNumberValidator(
      serialNumber.value
    );

    if (serialNumberError) {
      setSerialNumber({ ...serialNumber, error: serialNumberError });
      return;
    }

    const license_defaults: DistributorLicense[] = [];

    license_defaults.push({
      key: "com.rehabtronics.rehab",
      expires: rehabExpiresDate ? rehabExpiresDate!.format("YYYY-MM-DD") : null,
      license_version: 1,
      options: rehabOptions,
      features: rehabLicenseFeatures
        .filter((elem) => rehabSelectionModel.includes(elem.id))
        .map((elem) => {
          return {
            name: elem.name,
            expires: elem.expires
              ? elem.expires.toISOString().split("T")[0]
              : null,
            options: elem.options,
          };
        }) as DistributorLicenseFeature[],
    });

    license_defaults.push({
      key: "com.rehabtronics.rejoyce",
      expires: rejoyceExpiresDate
        ? rejoyceExpiresDate!.format("YYYY-MM-DD")
        : null,
      license_version: 1,
      options: rejoyceOptions,
      features: rejoyceLicenseFeatures
        .filter((elem) => rejoyceSelectionModel.includes(elem.id))
        .map((elem) => {
          return {
            name: elem.name,
            expires: elem.expires
              ? elem.expires.toISOString().split("T")[0]
              : null,
            options: elem.options,
          };
        }) as DistributorLicenseFeature[],
    });

    const count = Number.parseInt(copies.value);

    const data: ReJoyceSerialNumber = {
      version: null,
      serial_number: serialNumber.value,
      distributor_id: Number.parseInt(distributorId),
      license_defaults,
    };

    const resp = await Network.Post(
      "rejoyce_serial_numbers/" +
        (isEdit
          ? `update/${count}/${Number.parseInt(editItem!.id)}`
          : "new/" + count),
      data
    );
    if (resp.status === 200) {
      handleClose(true);
    } else if (resp.status === 500) {
      setSerialNumber({
        value: serialNumber.value,
        error: "Serial Number already exists",
      });
    }
  };

  const handleRehabDistributorDefaultsChange = async (
    event: SelectChangeEvent
  ) => {
    setRehabDistributorDefaultsId(event.target.value);

    const defaults = rehabDistributorDefaults.filter(
      (elem) => elem.id === Number.parseInt(event.target.value)
    )[0];

    prepareFeaturesList(defaults, rehabLicenseTypeFeatures!).then((result) => {
      let { selections, features } = result as FeatureList;
      setRehabSelectionModel(selections);
      setRehabLicenseFeatures(features);
    });
  };

  const processRehabRowUpdate = async (newRow: GridRowModel) => {
    const updatedRow = newRow as LicenseFeaturesDisplay;
    setRehabLicenseFeatures(
      rehabLicenseFeatures.map((row) =>
        row.id === updatedRow.id ? updatedRow : row
      )
    );
    return newRow;
  };

  const handleRejoyceDistributorDefaultsChange = async (
    event: SelectChangeEvent
  ) => {
    setRejoyceDistributorDefaultsId(event.target.value);

    const defaults = rejoyceDistributorDefaults.filter(
      (elem) => elem.id === Number.parseInt(event.target.value)
    )[0];

    prepareFeaturesList(defaults, rejoyceLicenseTypeFeatures!).then(
      (result) => {
        let { selections, features } = result as FeatureList;
        setRejoyceSelectionModel(selections);
        setRejoyceLicenseFeatures(features);
      }
    );
  };

  const processRejoyceRowUpdate = async (newRow: GridRowModel) => {
    const updatedRow = newRow as LicenseFeaturesDisplay;
    setRejoyceLicenseFeatures(
      rejoyceLicenseFeatures.map((row) =>
        row.id === updatedRow.id ? updatedRow : row
      )
    );
    return newRow;
  };

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        handleClose(false);
      }}
    >
      <DialogTitle>
        {isEdit ? "Edit Rejoyce Serial Number" : "New Rejoyce Serial Number"}
        <IconButton
          aria-label="close"
          onClick={() => handleClose(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.primary.main,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <ValidatedTextField
          label="Serial Number"
          value={serialNumber.value}
          onChange={(event: any) => {
            setSerialNumber({
              value: event.target.value,
              error: "",
            });
          }}
          errorText={serialNumber.error}
        />
        <FormControl fullWidth size="small" sx={{ mt: 2 }}>
          <InputLabel id="distributor-name-label">Distributor Name</InputLabel>
          <Select
            labelId="distributor-name-label"
            id="distributor-name"
            value={distributorId}
            label="Distributor Name"
            onChange={handleDistributorChange}
          >
            {distributors?.map((element) => (
              <MenuItem key={element.id} value={element.id.toString()}>
                {element.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <ValidatedTextField
          sx={{ mt: 3 }}
          type="number"
          label="Allowed amount of software copies"
          value={copies.value}
          onChange={(event: any) => {
            let value = Number.parseInt(event.target.value);
            if (value > 0 && value < 10)
              setCopies({
                value: event.target.value,
                error: "",
              });
          }}
          errorText={copies.error}
        />
        <Paper sx={{ p: "10px", mt: 2 }} elevation={1}>
          <Typography sx={{ mb: 1, fontWeight: "bold" }}>
            ReHab License Template
          </Typography>
          <FormControl fullWidth size="small" sx={{ mt: 2 }}>
            <InputLabel id="distributor-defaults-label">
              License Template
            </InputLabel>
            <Select
              labelId="distributor-defaults-label"
              id="distributor-defaults"
              value={rehabDistributorDefaultsId}
              label={"License Template"}
              onChange={handleRehabDistributorDefaultsChange}
            >
              {rehabDistributorDefaults?.map((element) => (
                <MenuItem key={element.id} value={element.id.toString()}>
                  {element.alias}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box sx={{ mt: 2, ml: 1, mb: 2 }}>
            <DatePicker
              label="Expires"
              value={rehabExpiresDate}
              minDate={moment()}
              onChange={(newValue) => {
                setRehabExpiresDate(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  sx={{ mr: 2 }}
                  variant="standard"
                  size="small"
                  {...params}
                  helperText={null}
                />
              )}
            />
          </Box>
          {/*<Box sx={{ mt: 3 }}>
        <TextField
          fullWidth
          label="Options"
          multiline
          rows={6}
          value={options}
          onChange={(event: any) => {
            setOptions(event.target.value);
          }}
        />
        </Box>*/}
          {rehabLicenseFeatures.length > 0 && (
            <Box sx={{ height: 400 }}>
              <DataGrid
                rows={rehabLicenseFeatures}
                columns={columns}
                processRowUpdate={processRehabRowUpdate}
                experimentalFeatures={{ newEditingApi: true }}
                checkboxSelection
                disableSelectionOnClick
                onSelectionModelChange={(newSelectionModel) => {
                  setRehabSelectionModel(newSelectionModel);
                }}
                selectionModel={rehabSelectionModel}
              />
            </Box>
          )}
        </Paper>
        <Paper sx={{ p: "10px", mt: 2 }} elevation={1}>
          <Typography sx={{ mb: 1, fontWeight: "bold" }}>
            ReJoyce License Template
          </Typography>
          <FormControl fullWidth size="small" sx={{ mt: 2 }}>
            <InputLabel id="distributor-defaults-label">
              License Template
            </InputLabel>
            <Select
              labelId="distributor-defaults-label"
              id="distributor-defaults"
              value={rejoyceDistributorDefaultsId}
              label={"License Template"}
              onChange={handleRejoyceDistributorDefaultsChange}
            >
              {rejoyceDistributorDefaults?.map((element) => (
                <MenuItem key={element.id} value={element.id.toString()}>
                  {element.alias}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box sx={{ mt: 2, ml: 1, mb: 2 }}>
            <DatePicker
              label="Expires"
              value={rejoyceExpiresDate}
              minDate={moment()}
              onChange={(newValue) => {
                setRejoyceExpiresDate(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  sx={{ mr: 2 }}
                  variant="standard"
                  size="small"
                  {...params}
                  helperText={null}
                />
              )}
            />
          </Box>
          {/*<Box sx={{ mt: 3 }}>
        <TextField
          fullWidth
          label="Options"
          multiline
          rows={6}
          value={options}
          onChange={(event: any) => {
            setOptions(event.target.value);
          }}
        />
        </Box>*/}
          {rejoyceLicenseFeatures.length > 0 && (
            <Box sx={{ height: 400 }}>
              <DataGrid
                rows={rejoyceLicenseFeatures}
                columns={columns}
                processRowUpdate={processRejoyceRowUpdate}
                experimentalFeatures={{ newEditingApi: true }}
                checkboxSelection
                disableSelectionOnClick
                onSelectionModelChange={(newSelectionModel) => {
                  setRejoyceSelectionModel(newSelectionModel);
                }}
                selectionModel={rejoyceSelectionModel}
              />
            </Box>
          )}
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleClose(false)}
          type="button"
          color="secondary"
          fullWidth
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          type="button"
          variant="contained"
          fullWidth
          disabled={
            !isEdit &&
            (distributorId === "" || rejoyceDistributorDefaultsId === "")
          }
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddEditRejoyceSerialNumberDialog;
