import * as React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import Network from "../network";
import {
  DistributorDefaultsListing,
  DistributorLicense,
  DistributorLicenseFeature,
  LicenseFeatures,
  LicenseFeaturesDisplay,
} from "../model";
import {
  DataGrid,
  GridColumns,
  GridRowModel,
  GridSelectionModel,
} from "@mui/x-data-grid";
import ValidatedTextField from "./ValidatedTextField";
import * as StringValidators from "../stringValidators";
import { MainTheme } from "../theme";
import { LicenseDisplayAdapter } from "../licenseDisplayAdapter";

const columns: GridColumns = [
  { field: "name", headerName: "Feature", width: 180 },
  { field: "options", headerName: "Options", editable: true, flex: 1 },
];

interface NewEditLicenseDefaultsDialogProps {
  open: boolean;
  closeDialog: any;
  distributor_id: number;
  rehab_default_present: boolean;
  editItem: DistributorDefaultsListing | null;
}

export function NewEditLicenseDefaultsDialog({
  open,
  closeDialog,
  distributor_id,
  rehab_default_present,
  editItem,
}: NewEditLicenseDefaultsDialogProps) {
  const hasBeenCalled = React.useRef(false);
  const [isLoading, setIsLoading] = React.useState(true);

  const [isEdit] = React.useState(editItem !== null);

  const [licenseType, setLicenseType] = React.useState("");

  const [licenseTypes, setLicenseTypes] = React.useState<
    LicenseFeatures[] | null
  >(null);

  const [name, setName] = React.useState({
    value: isEdit ? editItem!.alias : "",
    error: "",
  });

  const [licenseFeatures, setLicenseFeatures] = React.useState<
    LicenseFeaturesDisplay[]
  >([]);

  const [selectionModel, setSelectionModel] =
    React.useState<GridSelectionModel>([]);

  const [options, setOptions] = React.useState(
    isEdit ? editItem!.license_defaults.options : ""
  );

  const constructor = async () => {
    if (hasBeenCalled.current) return;
    hasBeenCalled.current = true;

    const resp = await Network.Get("licenses");
    if (resp.status === 200) {
      let data = resp.data as LicenseFeatures[];

      if (
        rehab_default_present &&
        !(isEdit && editItem!.license_defaults.key === "com.rehabtronics.rehab")
      )
        data = data.filter((elem) => elem.key !== "com.rehabtronics.rehab");

      setLicenseTypes(data);

      if (isEdit) {
        setLicenseType(editItem!.license_defaults.key);

        const license = data.filter(
          (license) => license.key === editItem!.license_defaults.key
        )[0];

        let features: LicenseFeaturesDisplay[] = [];
        let selections: number[] = [];
        license.features.forEach((feature) => {
          const itemFeature: DistributorLicenseFeature | undefined =
            editItem!.license_defaults.features.find(
              (elem) => elem.name === feature
            );

          if (itemFeature !== undefined) selections.push(features.length);

          features.push({
            id: features.length,
            name: feature,
            expires: null,
            options:
              itemFeature !== undefined
                ? itemFeature.options
                  ? itemFeature.options
                  : ""
                : "",
          });
        });
        setSelectionModel(selections);
        setLicenseFeatures(features);
      }
    }

    setIsLoading(false);
  };

  React.useEffect(() => {
    constructor();
  });

  const processRowUpdate = async (newRow: GridRowModel) => {
    const updatedRow = newRow as LicenseFeaturesDisplay;
    setLicenseFeatures(
      licenseFeatures.map((row) =>
        row.id === updatedRow.id ? updatedRow : row
      )
    );
    return newRow;
  };

  const handleSubmit = async () => {
    const nameError = StringValidators.fieldNotEmptyValidator(name.value);

    if (nameError) {
      setName({ ...name, error: nameError });
      return;
    }

    if ((!isEdit && (name.value === "ReHab Default" || name.value === "ReJoyce Default")) ||
        ((isEdit && ((name.value === "ReHab Default" && editItem!.alias !== "ReHab Default")
         || (name.value === "ReJoyce Default" && editItem!.alias !== "ReJoyce Default"))))) {
      setName({ ...name, error: "Invalid name" });
      return;
    }

    let features: DistributorLicenseFeature[] = [];
    licenseFeatures.forEach((feature) => {
      if (selectionModel.includes(feature.id))
        features.push({
          name: feature.name,
          expires: null, // Don't set expiration date on distributor defaults
          options: feature.options,
        });
    });

    let resp;
    if (isEdit) {
      editItem!.alias = name.value;
      editItem!.license_defaults.features = features;
      editItem!.license_defaults.key = licenseType;
      editItem!.license_defaults.options = options;

      resp = await Network.Post(`distributor_defaults/update`, editItem!);
    } else {
      const newLicenseDefaults: DistributorLicense = {
        license_version: 1,
        key: licenseType,
        expires: null, // Don't set expiration date on distributor defaults
        options: options,
        features: features,
      };

      resp = await Network.Post(
        `distributor_defaults/new?distributor_id=${distributor_id}&defaults_alias=${name.value}`,
        newLicenseDefaults
      );
    }

    if (resp.status === 200) {
      closeDialog(true);
    }
  };

  const handleLicenseTypeChange = (event: SelectChangeEvent) => {
    setLicenseType(event.target.value as string);
    const license = licenseTypes!.filter(
      (license) => license.key === (event.target.value as string)
    )[0];

    let features: LicenseFeaturesDisplay[] = [];
    let selections: number[] = [];
    license.features.forEach((feature) => {
      selections.push(features.length);
      features.push({
        id: features.length,
        name: feature,
        expires: null,
        options: "",
      });
    });
    setSelectionModel(selections);
    setLicenseFeatures(features);
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        closeDialog(false);
      }}
    >
      <DialogTitle>
        {isEdit ? "Edit License Template" : "New License Template"}
      </DialogTitle>
      {!isLoading && (
        <DialogContent>
          <FormControl fullWidth size="small" sx={{ mt: 1 }}>
            <InputLabel id="license-type-label">License Type</InputLabel>
            <Select
              labelId="license-type-label"
              id="license-type"
              value={licenseType}
              label="License Type"
              onChange={handleLicenseTypeChange}
            >
              {licenseTypes?.map((type) => (
                <MenuItem key={type.key} value={type.key}>
                  {LicenseDisplayAdapter.GetDisplayName(type.key)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <ValidatedTextField
            autoComplete="name"
            label="Alias"
            value={name.value}
            onChange={(event: any) => {
              setName({
                value: event.target.value,
                error: "",
              });
            }}
            errorText={name.error}
          />
          <Box sx={{ mt: 1 }}>
            <TextField
              fullWidth
              label="Options"
              multiline
              rows={6}
              value={options}
              onChange={(event: any) => {
                setOptions(event.target.value);
              }}
            />
          </Box>
          {licenseFeatures.length > 0 && (
            <Box sx={{ height: 400 }}>
              <DataGrid
                rows={licenseFeatures}
                columns={columns}
                processRowUpdate={processRowUpdate}
                experimentalFeatures={{ newEditingApi: true }}
                checkboxSelection
                disableSelectionOnClick
                onSelectionModelChange={(newSelectionModel) => {
                  setSelectionModel(newSelectionModel);
                }}
                selectionModel={selectionModel}
              />
            </Box>
          )}
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={() => closeDialog(false)} color="secondary" fullWidth>
          Cancel
        </Button>
        <Button
          disabled={licenseType === ""}
          onClick={handleSubmit}
          variant="contained"
          fullWidth
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export interface DeleteLicenseDefaultsDialogProps {
  open: boolean;
  handleClose: any;
  deleteItem: DistributorDefaultsListing;
}

export function DeleteLicenseDefaultsDialog({
  open,
  handleClose,
  deleteItem,
}: DeleteLicenseDefaultsDialogProps) {
  const handleSubmit = async () => {
    const resp = await Network.Post("distributor_defaults/delete", deleteItem);
    if (resp.status === 200) handleClose(true);
  };

  return (
    <Dialog open={open} keepMounted onClose={() => handleClose(false)}>
      <DialogTitle
        sx={{
          backgroundColor: MainTheme.palette.error.main,
          color: MainTheme.palette.common.white,
        }}
      >
        Delete Distributor Defaults
      </DialogTitle>
      <DialogContent
        dividers
      >{`Delete license template ${deleteItem.alias}?`}</DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)} color="secondary" fullWidth>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="error"
          fullWidth
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
