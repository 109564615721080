import { UserRole } from "./model";

export abstract class UserRoleDisplayAdapter {
  public static GetDisplayName(userRole: UserRole): string {
    switch (userRole) {
      case UserRole.Admin:
        return "Administrator";
      case UserRole.User:
        return "Internal User";
      case UserRole.Distributor:
        return "Distributor";
    }
    return "";
  }
}
