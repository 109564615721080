import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Network from "../network";
import { UserDataDisplayEntry } from "../model";
import { Box, DialogActions, DialogContentText } from "@mui/material";

export interface ResetUserPasswordDialogProps {
  open: boolean;
  handleClose: any;
  editItem: UserDataDisplayEntry;
}

function ResetUserPasswordDialog({
  open,
  handleClose,
  editItem,
}: ResetUserPasswordDialogProps) {
  const [newUserPassword, setNewUserPassword] = React.useState("");

  const handleResetPassword = async () => {
    const resp = await Network.Get(
      "users/resetpassword/" + Number.parseInt(editItem!.id)
    );

    if (resp.status === 200) {
      setNewUserPassword(resp.data);
    }
  };

  return (
    <Dialog open={open} keepMounted onClose={() => handleClose(false)}>
      <DialogTitle>
        Reset User Password
        <IconButton
          aria-label="close"
          onClick={() => handleClose(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.primary.main,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {newUserPassword === "" ? (
          <Box>
            <DialogContentText>
              Do you want to reset password for user <b>{editItem.email}</b>?
            </DialogContentText>
          </Box>
        ) : (
          <DialogContentText>
            User created with password <b>{newUserPassword}</b>, please send it
            to them! It will never be displayed again!
          </DialogContentText>
        )}
      </DialogContent>
      {newUserPassword === "" ? (
        <DialogActions>
          <Button
            onClick={() => handleClose(false)}
            type="button"
            fullWidth
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            onClick={handleResetPassword}
            type="button"
            fullWidth
            variant="contained"
          >
            Reset Password
          </Button>
        </DialogActions>
      ) : (
        <Button
          onClick={() => handleClose(true)}
          type="button"
          fullWidth
          variant="contained"
          sx={{ mt: 4 }}
        >
          Ok
        </Button>
      )}
    </Dialog>
  );
}

export default ResetUserPasswordDialog;
