import { Box, Paper, Typography } from "@mui/material";
import * as React from "react";
import { StoreStats, UserData, UserRole } from "../model";
import Network from "../network";
import { MainTheme } from "../theme";

export interface DashboardViewProps {
  userData: UserData;
}

function DashboardView({ userData, ...props }: DashboardViewProps) {
  const hasBeenCalled = React.useRef(false);

  const [stats, setStats] = React.useState<StoreStats | null>(null);

  const constructor = async () => {
    if (hasBeenCalled.current) return;
    hasBeenCalled.current = true;

    const resp = await Network.Get("dashboard");
    if (resp.status === 200) {
      setStats(resp.data);
    }
  };

  React.useEffect(() => {
    constructor();
  });

  return (
    <Box>
      <Box
        sx={{
          backgroundColor: MainTheme.palette.primary.main,
          color: MainTheme.palette.common.white,
          p: 3,
          m: 2,
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          Hello, {userData.name}.
        </Typography>
        <Typography variant="h6">I hope you are having a great day!</Typography>
      </Box>
      {stats && (
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Paper sx={{ flexGrow: 1, p: 5, m: 2 }} elevation={5}>
            <Typography
              variant="h4"
              sx={{ fontWeight: "bold", textAlign: "center" }}
            >
              {stats.num_license_keys}
            </Typography>
            <Typography variant="h6" sx={{ textAlign: "center" }}>
              License Keys
            </Typography>
          </Paper>
          <Paper sx={{ flexGrow: 1, p: 5, m: 2 }} elevation={5}>
            <Typography
              variant="h4"
              sx={{ fontWeight: "bold", textAlign: "center" }}
            >
              {stats.num_licenses}
            </Typography>
            <Typography variant="h6" sx={{ textAlign: "center" }}>
              Licenses
            </Typography>
          </Paper>
          {userData.user_role !== UserRole.Distributor && (
            <Paper sx={{ flexGrow: 1, p: 5, m: 2 }} elevation={5}>
              <Typography
                variant="h4"
                sx={{ fontWeight: "bold", textAlign: "center" }}
              >
                {stats.num_registrations}
              </Typography>
              <Typography variant="h6" sx={{ textAlign: "center" }}>
                Registrations
              </Typography>
            </Paper>
          )}
        </Box>
      )}
    </Box>
  );
}

export default DashboardView;
