export abstract class LicenseDisplayAdapter {
  public static GetDisplayName(licenseType: string): string {
    switch (licenseType) {
      case "com.rehabtronics.rehab":
        return "ReHab";
      case "com.rehabtronics.rejoyce":
        return "ReJoyce";
    }

    return "";
  }

  public static GetType(displayName: string): string {
    switch (displayName) {
      case "ReHab":
        return "com.rehabtronics.rehab";
      case "ReJoyce":
        return "com.rehabtronics.rejoyce";
    }

    return "";
  }
}
