import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import * as React from "react";
import DistributorItemView from "../components/DistributorItemView";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import { DistributorListing } from "../model";
import Network from "../network";
import { MainTheme } from "../theme";
import {
  AddEditDistributorDialog,
  DeleteDistributorDialog,
} from "../components/AddEditDeleteDistributorDialog";

function DistirbutorsView() {
  const hasBeenCalled = React.useRef(false);

  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(
    null
  );
  const openMenu = Boolean(menuAnchorEl);

  const handleClose = () => {
    setMenuAnchorEl(null);
  };

  const [addEditDistributorDialogOpen, setAddEditDistributorDialogOpen] =
    React.useState(false);

  const handleAddEditDistributorDialogClose = (updated: boolean) => {
    if (updated) reloadData();

    setAddEditDistributorDialogOpen(false);
    setSelectedDistributorItem(null);
  };

  const [deleteDistributorDialogOpen, setDeleteDistributorDialogOpen] =
    React.useState(false);

  const handleDeleteDistributorDialogClose = (updated: boolean) => {
    if (updated) reloadData();

    setDeleteDistributorDialogOpen(false);
    setSelectedDistributorItem(null);
  };

  const [selectedDistributorItem, setSelectedDistributorItem] =
    React.useState<DistributorListing | null>(null);

  const [distributors, setDistributors] = React.useState<DistributorListing[]>(
    []
  );

  const [selectedDistributor, setSelectedDistributor] =
    React.useState<DistributorListing | null>(null);

  const reloadData = async () => {
    const resp = await Network.Get("distributors");
    if (resp.status === 200) {
      setDistributors(resp.data);
    }
  };

  const constructor = () => {
    if (hasBeenCalled.current) return;
    hasBeenCalled.current = true;

    reloadData();
  };

  React.useEffect(() => {
    constructor();
  });

  return (
    <Box>
      {addEditDistributorDialogOpen && (
        <AddEditDistributorDialog
          editItem={selectedDistributorItem}
          open={addEditDistributorDialogOpen}
          handleClose={handleAddEditDistributorDialogClose}
        />
      )}
      {deleteDistributorDialogOpen && (
        <DeleteDistributorDialog
          deleteItem={selectedDistributorItem!}
          open={deleteDistributorDialogOpen}
          handleClose={handleDeleteDistributorDialogClose}
        />
      )}
      {selectedDistributor === null ? (
        <Box>
          <Button
            variant="outlined"
            onClick={() => setAddEditDistributorDialogOpen(true)}
            startIcon={<AddIcon />}
          >
            New Distributor
          </Button>
          <List>
            {distributors.map((element, index) => (
              <ListItem
                key={index}
                disablePadding
                sx={{
                  border: "1px solid " + MainTheme.palette.grey[300],
                  borderRadius: 1,
                  mt: 1,
                  mb: 1,
                }}
                secondaryAction={
                  <Box>
                    <Tooltip title="Edit Distributor">
                      <IconButton
                        sx={{ pr: 2 }}
                        edge="end"
                        aria-label="edit"
                        onClick={() => {
                          setSelectedDistributorItem(element);
                          setAddEditDistributorDialogOpen(true);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <IconButton
                      edge="end"
                      aria-label="menu"
                      id={"menu" + element.id}
                      onClick={(event: React.MouseEvent<HTMLElement>) => {
                        setSelectedDistributorItem(element);
                        setMenuAnchorEl(event.currentTarget);
                      }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      anchorEl={menuAnchorEl}
                      open={openMenu}
                      onClose={handleClose}
                      onClick={handleClose}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          overflow: "visible",
                          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.22))",
                          mt: 1.5,
                          "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                          },
                        },
                      }}
                      transformOrigin={{ horizontal: "right", vertical: "top" }}
                      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                    >
                      <MenuItem
                        onClick={() => setDeleteDistributorDialogOpen(true)}
                      >
                        <ListItemIcon>
                          <DeleteIcon fontSize="small" />
                        </ListItemIcon>
                        Delete
                      </MenuItem>
                    </Menu>
                  </Box>
                }
              >
                <ListItemButton onClick={() => setSelectedDistributor(element)}>
                  <ListItemText primary={element.name} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      ) : (
        <DistributorItemView
          distributor={selectedDistributor!}
          goBack={() => setSelectedDistributor(null)}
        />
      )}
    </Box>
  );
}

export default DistirbutorsView;
