import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import * as StringValidators from "../stringValidators";
import ValidatedTextField from "./ValidatedTextField";
import Network from "../network";
import { Box, DialogActions, DialogContentText } from "@mui/material";
import { RegistrationDisplayEntry } from "../model";

interface NewRegistrationBody {
  license_key: string;
  organization: string;
  contact_name: string;
  contact_email: string;
  contact_phone: string;
  address: string;
  city: string;
  region: string;
  country: string;
}

interface NewRegistrationDialogProps {
  open: boolean;
  handleClose: any;
  registrations: RegistrationDisplayEntry[];
}

function NewRegistrationDialog({
  open,
  handleClose,
  registrations,
}: NewRegistrationDialogProps) {
  const [licenseKey, setLicenseKey] = React.useState({
    value: "",
    error: "",
  });
  const [organization, setOrganization] = React.useState({
    value: "",
    error: "",
  });
  const [name, setName] = React.useState({
    value: "",
    error: "",
  });
  const [email, setEmail] = React.useState({
    value: "",
    error: "",
  });
  const [phone, setPhone] = React.useState({
    value: "",
    error: "",
  });
  const [address, setAddress] = React.useState({
    value: "",
    error: "",
  });
  const [city, setCity] = React.useState({
    value: "",
    error: "",
  });
  const [region, setRegion] = React.useState({
    value: "",
    error: "",
  });
  const [country, setCountry] = React.useState({
    value: "",
    error: "",
  });

  const [updateRegistrationDialogOpen, setUpdateRegistrationDialogOpen] =
    React.useState(false);

  const handleRegister = async () => {
    const licenseKeyError = StringValidators.licenseKeyValidator(
      licenseKey.value
    );
    const organizationError = StringValidators.fieldNotEmptyValidator(
      organization.value
    );
    const nameError = StringValidators.nameValidator(name.value);
    const emailError = StringValidators.emailValidator(email.value);
    const phoneError = StringValidators.fieldNotEmptyValidator(phone.value);
    const addressError = StringValidators.fieldNotEmptyValidator(address.value);
    const cityError = StringValidators.fieldNotEmptyValidator(city.value);
    const regionError = StringValidators.fieldNotEmptyValidator(region.value);
    const countryError = StringValidators.fieldNotEmptyValidator(country.value);

    if (
      licenseKeyError ||
      organizationError ||
      nameError ||
      emailError ||
      phoneError ||
      addressError ||
      cityError ||
      regionError ||
      countryError
    ) {
      setLicenseKey({ ...licenseKey, error: licenseKeyError });
      setOrganization({ ...organization, error: organizationError });
      setName({ ...name, error: nameError });
      setEmail({ ...email, error: emailError });
      setPhone({ ...phone, error: phoneError });
      setAddress({ ...address, error: addressError });
      setCity({ ...city, error: cityError });
      setRegion({ ...region, error: regionError });
      setCountry({ ...country, error: countryError });
      return;
    }

    if (!updateRegistrationDialogOpen) {
      const rows = registrations.filter(
        (entry) => entry.license_key === licenseKey.value
      );
      if (rows.length > 0) {
        setUpdateRegistrationDialogOpen(true);
        return;
      }
    }

    const body: NewRegistrationBody = {
      license_key: licenseKey.value,
      organization: organization.value,
      contact_name: name.value,
      contact_email: email.value,
      contact_phone: phone.value,
      address: address.value,
      city: city.value,
      region: region.value,
      country: country.value,
    };
    const resp = await Network.Post("registrations/new", body);
    if (resp.status === 200) {
      setUpdateRegistrationDialogOpen(false);
      handleClose(true);
    } else if (resp.status === 404) {
      setLicenseKey({ ...licenseKey, error: "License Key not found" });
    } else if (resp.status === 400) {
      setLicenseKey({ ...licenseKey, error: "Incorrect license key" });
    }
  };

  return (
    <Box>
      <Dialog
        open={open}
        keepMounted
        onClose={(event, reason) => {
          if (reason && reason === "backdropClick") return;
          handleClose(false);
        }}
      >
        <DialogTitle>
          {"New Registration"}
          <IconButton
            aria-label="close"
            onClick={() => handleClose(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.primary.main,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <ValidatedTextField
            label="License Key"
            value={licenseKey.value}
            onChange={(event: any) => {
              setLicenseKey({
                value: event.target.value,
                error: "",
              });
            }}
            errorText={licenseKey.error}
          />
          <ValidatedTextField
            label="Organization"
            value={organization.value}
            onChange={(event: any) => {
              setOrganization({
                value: event.target.value,
                error: "",
              });
            }}
            errorText={organization.error}
          />
          <ValidatedTextField
            autoComplete="name"
            label="Contact Name"
            value={name.value}
            onChange={(event: any) => {
              setName({
                value: event.target.value,
                error: "",
              });
            }}
            errorText={name.error}
          />
          <ValidatedTextField
            autoComplete="email"
            label="Email Address"
            value={email.value}
            onChange={(event: any) => {
              setEmail({
                value: event.target.value,
                error: "",
              });
            }}
            errorText={email.error}
          />
          <ValidatedTextField
            autoComplete="mobile"
            label="Contact Phone Number"
            value={phone.value}
            onChange={(event: any) => {
              setPhone({
                value: event.target.value,
                error: "",
              });
            }}
            errorText={phone.error}
          />
          <ValidatedTextField
            autoComplete="address"
            label="Address"
            value={address.value}
            onChange={(event: any) => {
              setAddress({
                value: event.target.value,
                error: "",
              });
            }}
            errorText={address.error}
          />
          <ValidatedTextField
            autoComplete="city"
            label="City"
            value={city.value}
            onChange={(event: any) => {
              setCity({
                value: event.target.value,
                error: "",
              });
            }}
            errorText={city.error}
          />
          <ValidatedTextField
            autoComplete="region"
            label="Region"
            value={region.value}
            onChange={(event: any) => {
              setRegion({
                value: event.target.value,
                error: "",
              });
            }}
            errorText={region.error}
          />
          <ValidatedTextField
            autoComplete="country"
            label="Country"
            value={country.value}
            onChange={(event: any) => {
              setCountry({
                value: event.target.value,
                error: "",
              });
            }}
            errorText={country.error}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleClose(false)}
            type="button"
            fullWidth
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            onClick={handleRegister}
            fullWidth
            type="button"
            variant="contained"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      {updateRegistrationDialogOpen && (
        <Dialog
          open={open}
          keepMounted
          onClose={() => setUpdateRegistrationDialogOpen(false)}
        >
          <DialogTitle>
            Update Registration Entry
            <IconButton
              aria-label="close"
              onClick={() => setUpdateRegistrationDialogOpen(false)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.primary.main,
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box>
              <DialogContentText>
                License Key already registered. Do you want to update License
                Key registration entry?
              </DialogContentText>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setUpdateRegistrationDialogOpen(false)}
              type="button"
              fullWidth
              variant="outlined"
              color="secondary"
            >
              Cancel
            </Button>
            <Button
              onClick={handleRegister}
              type="button"
              fullWidth
              variant="contained"
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
}

export default NewRegistrationDialog;
