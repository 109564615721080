import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import * as StringValidators from "../stringValidators";
import ValidatedTextField from "./ValidatedTextField";
import Network from "../network";
import { DialogActions, Divider } from "@mui/material";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Profile({ open, handleClose, userData }) {
  const [email, setEmail] = React.useState({
    value: userData.email,
    error: "",
  });
  const [name, setName] = React.useState({
    value: userData.name,
    error: "",
  });
  const [password, setPassword] = React.useState({ value: "", error: "" });
  const [newPassword, setNewPassword] = React.useState({
    value: "",
    error: "",
  });
  const [newPasswordRepeat, setNewPasswordRepeat] = React.useState({
    value: "",
    error: "",
  });

  React.useEffect(() => {
    if (open) {
      setEmail({ value: userData.email, error: "" });
      setName({ value: userData.name, error: "" });
    }
  }, [open, userData]);

  const onPasswordChange = async () => {
    const passwordError = StringValidators.passwordValidator(password.value);
    const newPasswordError = StringValidators.passwordValidator(
      newPassword.value
    );
    const newPasswordRepeatError = StringValidators.passwordValidator(
      newPasswordRepeat.value
    );

    if (passwordError || newPasswordError || newPasswordRepeatError) {
      setPassword({ ...password, error: passwordError });
      setNewPassword({ ...newPassword, error: newPasswordError });
      setNewPasswordRepeat({
        ...newPasswordRepeat,
        error: newPasswordRepeatError,
      });
      return;
    }

    const passwordChangeError = StringValidators.passwordChangeValidator(
      password.value,
      newPassword.value,
      newPasswordRepeat.value
    );
    if (passwordChangeError) {
      setNewPasswordRepeat({
        ...newPasswordRepeat,
        error: passwordChangeError,
      });
      return;
    }

    const resp = await Network.Post("auth/change_pass", {
      password: password.value,
      new_password: newPassword.value,
    });
    if (resp.status === 200) {
      handleClose();
    } else if (resp.status === 401) {
      setPassword({
        ...password,
        error: "Incorrect password",
      });
    }
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        handleClose();
      }}
    >
      <DialogTitle>
        {"Profile Settings"}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.primary.main,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <ValidatedTextField
          variant="filled"
          InputProps={{
            readOnly: true,
          }}
          autoComplete="email"
          label="Email Address"
          value={email.value}
          onChange={(event: any) => {
            setEmail({
              value: event.target.value,
              error: "",
            });
          }}
          errorText={email.error}
        />
        <ValidatedTextField
          variant="filled"
          InputProps={{
            readOnly: true,
          }}
          autoComplete="name"
          label="Full Name"
          value={name.value}
          onChange={(event: any) => {
            setName({
              value: event.target.value,
              error: "",
            });
          }}
          errorText={name.error}
        />
        <Divider sx={{ mt: 1, ml: -3, mr: -3 }} />
        <ValidatedTextField
          type="password"
          label="Password"
          autoComplete="current-password"
          value={password.value}
          onChange={(event: any) => {
            setPassword({
              value: event.target.value,
              error: "",
            });
          }}
          errorText={password.error}
        />
        <ValidatedTextField
          type="password"
          label="New Password"
          autoComplete="current-password"
          value={newPassword.value}
          onChange={(event: any) => {
            setNewPassword({
              value: event.target.value,
              error: "",
            });
          }}
          errorText={newPassword.error}
        />
        <ValidatedTextField
          type="password"
          label="New Password (Again)"
          autoComplete="current-password"
          value={newPasswordRepeat.value}
          onChange={(event: any) => {
            setNewPasswordRepeat({
              value: event.target.value,
              error: "",
            });
          }}
          errorText={newPasswordRepeat.error}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} type="button" fullWidth color="secondary">
          Cancel
        </Button>
        <Button
          onClick={onPasswordChange}
          type="button"
          fullWidth
          variant="contained"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Profile;
