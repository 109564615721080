import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
} from "@mui/material";
import {
  DataGrid,
  GridActionsCellItem,
  GridColumns,
  GridRowId,
  GridToolbar,
  GridToolbarContainer,
  GridValidRowModel,
} from "@mui/x-data-grid";
import * as React from "react";
import { DistributorListing, UserDataDisplayEntry, UserDataEx } from "../model";
import Network from "../network";
import { useWindowSize } from "../tools";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import SyncLockIcon from "@mui/icons-material/SyncLock";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import { MainTheme } from "../theme";
import AddEditUserDialog from "../components/AddEditUserDialog";
import ResetUserPasswordDialog from "../components/ResetUserPasswordDialog";
import { UserRoleDisplayAdapter } from "../userRoleDisplayAdapter";

interface ConfirmationDialogArgs {
  resolve: () => Promise<void>;
  caption: string;
  question: string;
}

interface EditToolbarProps {
  handleAddUser: () => void;
}

function EditToolbar(props: EditToolbarProps) {
  const { handleAddUser } = props;

  return (
    <GridToolbarContainer>
      <GridToolbar sx={{ flex: 1 }} />
      <Button color="primary" startIcon={<AddIcon />} onClick={handleAddUser}>
        Add User
      </Button>
    </GridToolbarContainer>
  );
}

function UserManagementView() {
  const [, height] = useWindowSize();
  const hasBeenCalled = React.useRef(false);
  const [isLoading, setIsLoading] = React.useState(true);

  const [rows, setRows] = React.useState<UserDataDisplayEntry[]>([]);

  const [distributors, setDistributors] = React.useState<DistributorListing[]>(
    []
  );

  const [addEditUserDialogOpen, setAddEditUserDialogOpen] =
    React.useState(false);

  const [editItem, setEditItem] = React.useState<UserDataDisplayEntry | null>(
    null
  );

  const handleAddEditUserDialogClose = (updated: boolean) => {
    if (updated) loadData(distributors);

    setEditItem(null);
    setAddEditUserDialogOpen(false);
  };

  const [confirmationDialogArgs, setConfirmationDialogArgs] =
    React.useState<ConfirmationDialogArgs | null>(null);

  const handleEditClick = (id: GridRowId) => async () => {
    let rowToUpdate = rows.filter(
      (row) => row.id === id
    )[0] as UserDataDisplayEntry;

    setEditItem(rowToUpdate);
    setAddEditUserDialogOpen(true);
  };

  const [resetPasswordDialogOpen, setResetPasswordDialogOpen] =
    React.useState(false);

  const [resetPasswordItem, setResetPasswordItem] =
    React.useState<UserDataDisplayEntry | null>(null);

  const handleResetPasswordDialogClose = (updated: boolean) => {
    if (updated) loadData(distributors);

    setResetPasswordDialogOpen(false);
    setResetPasswordItem(null);
  };

  const handleResetPasswordClick = (id: GridRowId) => async () => {
    let rowToUpdate = rows.filter(
      (row) => row.id === id
    )[0] as UserDataDisplayEntry;

    if (rowToUpdate.disabled === "Yes") return;

    setResetPasswordItem(rowToUpdate);
    setResetPasswordDialogOpen(true);
  };

  const handleDisableClick = (id: GridRowId) => async () => {
    let row = rows.filter((row) => row.id === id)[0] as UserDataDisplayEntry;

    if (row.disabled === "Yes") return;

    setConfirmationDialogArgs({
      caption: "Disable",
      question: `Do you want to disable user ${row.email}?`,
      resolve: async () => {
        const resp = await Network.Get("users/disable/" + row.id);
        if (resp.status === 200) {
          const tempRows = [...rows];
          tempRows.find((elem) => elem.id === row.id)!.disabled = "Yes";
          setRows(tempRows);
        }
      },
    });
  };

  const loadData = async (distributors: DistributorListing[]) => {
    const resp = await Network.Get("users");
    if (resp.status === 200) {
      const data = resp.data as UserDataEx[];
      let rows: UserDataDisplayEntry[] = [];
      data.forEach((elem) => {
        let created_at = new Date(elem.created_at);

        let distributor = "-";
        if (elem.distributor_id !== null) {
          let select = distributors.filter(
            (distr) => distr.id === elem.distributor_id
          );
          if (select.length > 0) distributor = select[0].name;
        }

        rows.push({
          id: elem.id,
          name: elem.name,
          email: elem.email,
          user_role: elem.user_role,
          user_role_display: UserRoleDisplayAdapter.GetDisplayName(
            elem.user_role
          ),
          distributor_id: elem.distributor_id,
          distributor: distributor,
          disabled: elem.disabled === 0 ? "No" : "Yes",
          created_at: `${created_at.toDateString()} (${created_at.toLocaleTimeString()})`,
          modified_at: elem.modified_at
            ? `${new Date(elem.modified_at).toDateString()} (${new Date(
                elem.modified_at
              ).toLocaleTimeString()})`
            : "-",
        });
      });
      setRows(rows);
    }
  };

  const constructor = async () => {
    if (hasBeenCalled.current) return;
    hasBeenCalled.current = true;

    const distributors = await loadDistributors();
    loadData(distributors);

    setIsLoading(false);
  };

  const loadDistributors = async (): Promise<DistributorListing[]> => {
    let resp = await Network.Get("distributors");
    let distributors: DistributorListing[] = [];
    if (resp.status === 200) {
      distributors = resp.data as DistributorListing[];
      setDistributors(distributors);
      return distributors;
    }
    return [];
  };

  React.useEffect(() => {
    constructor();
  });

  const getUserStatus = (row: GridValidRowModel): string => {
    let currentRow = row as UserDataDisplayEntry;

    if (currentRow.disabled === "Yes") return "user-disabled";
    return "";
  };

  const columns: GridColumns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    { field: "user_role_display", headerName: "Role", width: 110 },
    { field: "distributor", headerName: "Distributor", flex: 1 },
    { field: "created_at", headerName: "Created At", width: 210 },
    { field: "modified_at", headerName: "Modified At", width: 210 },
    { field: "disabled", headerName: "Disabled", width: 70 },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 130,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={
              <Tooltip title="Edit User">
                <EditIcon />
              </Tooltip>
            }
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <Tooltip title="Reset User Password">
                <SyncLockIcon />
              </Tooltip>
            }
            label="Reset Password"
            className="textPrimary"
            onClick={handleResetPasswordClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <Tooltip title="Disable User">
                <PersonOffIcon />
              </Tooltip>
            }
            label="Delete"
            onClick={handleDisableClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const handleCancel = () => {
    setConfirmationDialogArgs(null);
  };

  const handleYes = async () => {
    const { resolve } = confirmationDialogArgs!;
    try {
      resolve();
      setConfirmationDialogArgs(null);
    } catch (error) {
      setConfirmationDialogArgs(null);
    }
  };

  const renderConfirmDialog = () => {
    if (!confirmationDialogArgs) {
      return null;
    }
    const { caption, question } = confirmationDialogArgs;
    return (
      <Dialog open={!!confirmationDialogArgs}>
        <DialogTitle
          sx={{
            backgroundColor: MainTheme.palette.error.main,
            color: MainTheme.palette.common.white,
          }}
        >
          {caption}
        </DialogTitle>
        <DialogContent dividers>{question}</DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="secondary" fullWidth>
            Cancel
          </Button>
          <Button
            onClick={handleYes}
            variant="contained"
            color="error"
            fullWidth
          >
            {caption}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Box
      sx={{
        textAlign: "center",
        "& .user-disabled": {
          bgcolor: (theme) => theme.palette.error.light + "DD",
          "&:hover": { bgcolor: (theme) => theme.palette.error.main },
        },
      }}
    >
      {addEditUserDialogOpen && (
        <AddEditUserDialog
          open={addEditUserDialogOpen}
          distributors={distributors}
          handleClose={handleAddEditUserDialogClose}
          editItem={editItem}
        />
      )}
      {resetPasswordDialogOpen && (
        <ResetUserPasswordDialog
          open={resetPasswordDialogOpen}
          handleClose={handleResetPasswordDialogClose}
          editItem={resetPasswordItem!}
        />
      )}
      {renderConfirmDialog()}
      <DataGrid
        loading={isLoading}
        rows={rows}
        columns={columns}
        components={{
          Toolbar: EditToolbar,
        }}
        componentsProps={{
          toolbar: {
            handleAddUser: () => setAddEditUserDialogOpen(true),
          },
        }}
        sx={{
          height: height * 0.75,
          boxShadow: 3,
        }}
        getRowClassName={(params) => getUserStatus(params.row)}
      />
    </Box>
  );
}

export default UserManagementView;
