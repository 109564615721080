import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import ValidatedTextField from "./ValidatedTextField";
import * as StringValidators from "../stringValidators";
import Network from "../network";

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://rehabtronics.com/">
        Rehabtronics Inc.
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function SignIn({ setUserData, ...props }) {
  const [email, setEmail] = React.useState({ value: "", error: "" });
  const [password, setPassword] = React.useState({ value: "", error: "" });

  const handleSubmit = async () => {
    const emailError = StringValidators.emailValidator(email.value);
    const passwordError = StringValidators.passwordValidator(password.value);

    if (emailError || passwordError) {
      setEmail({ ...email, error: emailError });
      setPassword({ ...password, error: passwordError });
      return;
    }

    const resp = await Network.Auth(email.value, password.value);
    if (resp.status === 200) {
      setUserData(resp.data);
    } else if (resp.status === 401) {
      setPassword({
        ...password,
        error: "Such combination of email and password doesn't exist",
      });
    }
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          justifyContent: "center",
          alignSelf: "center",
        }}
      >
        <Box>
          <img src="./rehabtronics.svg" width="400px" alt="React Logo" />
          <Box sx={{ mt: 1, flexGrow: 1 }}>
            <ValidatedTextField
              autoComplete="email"
              label="Email Address"
              value={email.value}
              onChange={(event: any) => {
                setEmail({
                  value: event.target.value,
                  error: "",
                });
              }}
              errorText={email.error}
            />
            <ValidatedTextField
              type="password"
              label="Password"
              autoComplete="current-password"
              value={password.value}
              onChange={(event: any) => {
                setPassword({
                  value: event.target.value,
                  error: "",
                });
              }}
              errorText={password.error}
            />
            <Button
              onClick={handleSubmit}
              type="button"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            {/* <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
            </Grid> */}
          </Box>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}
