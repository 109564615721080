import * as React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useWindowSize } from "../tools";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import moment from "moment";

interface LogEntry {
  id: number;
  timestamp: string;
  entry: string;
}

const columns: GridColDef[] = [
  { field: "timestamp", headerName: "Time", width: 250 },
  { field: "entry", headerName: "Log", flex: 1, editable: true },
];

export function ParseLogFileName(fileName: string): string {
  if (fileName.startsWith("log.")) {
    let displayName = "Log - ";
    let date = moment(fileName.substring(4), "YYYYMMDDTHHmmss").toDate();
    displayName += `${date.toDateString()} (${date.toLocaleTimeString()})`;
    return displayName;
  }
  return "Current Log";
}

function SelectedLogFileView({ logFileName, logFileContent, goBack }) {
  const [, height] = useWindowSize();
  const hasBeenCalled = React.useRef(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [rows, setRows] = React.useState<LogEntry[]>([]);

  const constructor = async () => {
    if (hasBeenCalled.current) return;
    hasBeenCalled.current = true;

    const lines = logFileContent.split(/\r?\n/);
    let entries: LogEntry[] = [];
    for (let i = 0; i < lines.length; ++i) {
      const line: string[] = lines[i].split("]:");
      if (line.length !== 2) continue;

      const date = new Date(line[0].substring(1));
      entries.push({
        id: i,
        timestamp: `${date.toDateString()} (${date.toLocaleTimeString()})`,
        entry: line[1],
      });
    }
    setRows(entries);

    setIsLoading(false);
  };

  React.useEffect(() => {
    constructor();
  });

  return (
    <Box>
      <Box sx={{ flexDirection: "row", display: "flex", mb: 1 }}>
        <IconButton color="primary" onClick={goBack}>
          <ArrowBackIcon />
        </IconButton>
        <Typography
          sx={{ flex: 1, textAlign: "center" }}
          variant="h4"
          color="primary"
        >
          {React.useMemo(() => ParseLogFileName(logFileName), [logFileName])}
        </Typography>
      </Box>
      <DataGrid
        loading={isLoading}
        rows={rows}
        columns={columns}
        components={{
          Toolbar: GridToolbar,
        }}
        sx={{
          height: height * 0.75,
          boxShadow: 3,
        }}
      />
    </Box>
  );
}

export default SelectedLogFileView;
